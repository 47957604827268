/* Global variables */
:root {
    --main-component-color: #ff9f59;
    --main-text-color: #dcdcdc;
}

*, *:before, *:after {
    box-sizing: border-box;
}
html {
    /* font-size: 14px; */
    background-color: #000;
    /* the two lines below were added to prevent the app main container scrollbar not displaying for some pages */
    overflow: -moz-scrollbars-vertical;
    overflow-y: auto;
}
body {
    color: var(--main-text-color);
}
.large {
    font-size: 3rem;
    position: absolute;
    top: 0;
    right: 10px;
}
.content {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1500px;
}
p.overview {
    font-size: 12px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.heading {
    width: 100%;
    margin-left: 1rem;
    font-weight: 900;
    font-size: 1.618rem;
    text-transform: uppercase;
    letter-spacing: 0.1ch;
    line-height: 1;
    padding-bottom: 0.5em;
    margin-bottom: 1rem;
    position: relative;
}
.heading:after {
    display: block;
    content: '';
    position: absolute;
    width: 60px;
    height: 4px;
    background: linear-gradient(135deg, #1a9be6, #1a57e6);
    bottom: 0;
}
.description {
    width: 100%;
    margin-top: 0;
    margin-left: 1rem;
    margin-bottom: 3rem;
}
.card {
    color: inherit;
    cursor: pointer;
    width: 100%;
    height: 0;
    perspective: 1000px;
    position: relative;
    padding-bottom: 130%;
    background-color: transparent;
}
@media screen and (max-width: 800px) {
    .card {
        width: calc(50% - 3rem);
   }
}
@media screen and (max-width: 500px) {
    .card {
        width: 100%;
   }
}
.front, .back {
    display: flex;
    border-radius: 6px;
    background-position: center;
    background-size: cover;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: ease-in-out 600ms;
}
.front {
    background-size: cover;
    background-color: #272727;
    padding: 2rem;
    font-size: 1.618rem;
    font-weight: 600;
    color: #fff;
    overflow: hidden;
    font-family: Poppins, sans-serif;
}
.front:before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, #1a9be6, #1a57e6);
    opacity: 0;
    z-index: -1;
}
.card:hover .front {
    transform: rotateY(180deg);
}
.card:nth-child(even):hover .front {
    transform: rotateY(-180deg);
}
.back {
    /*background-image: linear-gradient(to right, #ffefba, #ffffff);*/
    background-color: #3D3938;
    transform: rotateY(-180deg);
    padding: 0 2em;
}
.card:hover .back {
    transform: rotateY(0deg);
    box-shadow: 0 0 30px 10px #704c4c;
}
.card:nth-child(even) .back {
    transform: rotateY(180deg);
}
.card:nth-child(even):hover .back {
    transform: rotateY(0deg);
}
.button {
    transform: translateZ(40px);
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    font-weight: bold;
    color: #fff;
    padding: 0.5em 1em;
    border-radius: 100px;
    font: inherit;
    background: linear-gradient(135deg, #1a9be6, #1a57e6);
    border: none;
    position: relative;
    transform-style: preserve-3d;
    transition: 300ms ease;
}
.button:before {
    transition: 300ms ease;
    position: absolute;
    display: block;
    content: '';
    transform: translateZ(-40px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    border-radius: 100px;
    left: 10px;
    top: 16px;
    box-shadow: 0 0 10px 10px rgba(26, 87, 230, 0.25);
    background-color: rgba(26, 87, 230, 0.25);
}

.button.delete-button {
    background-color: rgba(230, 87, 230, 0.25);
    background: linear-gradient(135deg, #e61a46, #e61a1a);
}
.button.delete-button:before {
    background-color: rgba(230, 87, 230, 0.25);
    box-shadow: 0 0 10px 10px rgba(230, 87, 230, 0.25);
}
.button:hover {
    transform: translateZ(55px);
}
.button:hover:before {
    transform: translateZ(-55px);
}
.button:active {
    transform: translateZ(20px);
}
.button:active:before {
    transform: translateZ(-20px);
    top: 12px;
    top: 12px;
}
.container.add {
   margin-top: 40px;
   margin-bottom: 20px;
}
.rating {
    font-size: 14px;
    font-style: italic;
}
.review-rating {
    font-size: 30px;
    font-weight: 900;
}
.card-label {
    font-size: 12px;
 }
.difficulty {
   font-size: 12px;
   margin-bottom: 4px;
}
.movie_gens {
    font-size: 11.5px;
}
.title {
    font-weight: bold;
    font-size: 14px;
}
.release_date {
    font-weight: normal;
    font-size: 12px;
}
.no-gutters {
    margin-right: 0;
    margin-left: 0;
  
    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
}
.gradient-background {
    background-image: linear-gradient(to right, #330000 0%, black 100%);
    background-size: 180% 180%;
    animation: gradient-animation 18s ease infinite;
}
.feature-section {
    background-color: #000;
}
.partner-section {
    background-color: #000;
}
.topbar-gradient-background {
    background-image: linear-gradient(to left, #005aa7, #fffde4);
    background-size: 180% 180%;
    animation: gradient-animation 18s ease infinite;
}
.counter-gradient-background {
    background-image: linear-gradient(to right, #8360c3, #2ebf91);
    background-size: 180% 180%;
    animation: gradient-animation 18s ease infinite;
}
.game-gradient-background {
    background-image: linear-gradient(to right, #8360c3, #2ebf91);
    background-size: 180% 180%;
    animation: gradient-animation 18s ease infinite;
}
.game-solid-background {
    background-color: rgba(36, 36, 36, 0.6);
}
@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.spinner-grow {
    color: #1e293a;
}
.spinner-border {
    color: #1e293a;
}
/* Remove bootstrap default on focus blue border */
*:focus {
    box-shadow: none !important;
}
.app-feature,
.auth-title {
    color: var(--main-component-color);
}
.app-title {
    font-family: "SnesItalic";
    font-size: 80px;
    color: var(--main-component-color);
}
.app-title-small {
    font-family: "SnesItalic";
    font-size: 40px;
    font-weight: 500;
    color: black;
}
.sidebar-item {
    font-family: "SnesItalic";
    font-size: 20px;
}
.user-display-name {
    font-size: 40px;
    color: '#dcdcdc';
}
.form-select,
.form-select:disabled,
.form-control:disabled,
.form-control {
    color: var(--main-text-color);
    background-color: #242424;
    border-width: thin;
    border-color: black;
}
.form-control:focus {
    background-color: #000;
    border-color: #242424;
    color: var(--main-text-color);
}
.button-custom,
.button-custom:hover {
    color: #000;
    background-color: var(--main-component-color);
    border-color: var(--main-component-color);
}
.button-outline-custom {
    color: var(--main-component-color);
    background-color: inherit;
    border-color: var(--main-component-color);
}
.button-outline-custom:hover {
    color: #000;
    background-color: var(--main-component-color);
    border-color: var(--main-component-color);
}
.button-outline-custom-invert {
    color: #000;
    background-color: var(--main-component-color);
    border-color: var(--main-component-color);
}
.button-outline-custom-invert:hover {
    color: #000;
    background-color: var(--main-component-color);
    border-color: var(--main-component-color);
}
.chip-outline-custom {
    color: var(--main-component-color);
    background-color: inherit;
    border-color: var(--main-component-color);
}
.chip-outline-custom:hover {
    color: #000;
    background-color: var(--main-component-color);
    border-color: var(--main-component-color);
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: black;
    background-color: var(--main-component-color);
    border-color: none;
}
.nav-tabs .nav-link {
    font-family: "SnesItalic";
    font-size: xx-large;
    color: var(--main-text-color);;
}
.nav-tabs {
    border-bottom: 0px solid transparent;
}
.nav-link.active {
    color: black
}
.nav-link {
    color: black;
}
.page-header {
    font-family: "SnesItalic";
    font-size: 60px;
    color: var(--main-component-color);
}
.user-header {
    font-family: "SnesItalic";
    font-size: 70px;
    color: var(--main-component-color);
}
.section-title {
    font-family: "SnesItalic";
    font-size: 40px;
    color: #FFB27A;
}
.page-title {
    font-family: "SnesItalic";
    font-size: 40px;
    color: #fff;
}
.games-count {
    font-size: 18px;
    color: #dcdcdc;
}
.search-results {
    font-family: "SnesItalic";
    font-size: 30px;
    color: #fff;
}
.page-subtitle {
    color: #FFB27A;
}
.gaming-count {
    font-size: 50px;
    color: var(--main-component-color);
}
.gaming-section {
    font-family: "SnesItalic";
    font-size: 45px;
    color: var(--main-component-color);
}
.game-title {
    color: white;
}
.game-summary {
    font-size: 14px;
}
.game-summary-link {
    color: white;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}
.game-summary-link:hover {
    color: var(--main-component-color);
}
.filtered-value {
    color: #fff;
}
.image-carousel {
    opacity: 0.7;
}
.game-counter {
    color: white;
    border-style: solid;
    border-width: thin;
    border-color: var(--main-component-color);
}
.game-counter:hover {
    color: #000;
    background-color: var(--main-component-color);
    border-color: var(--main-component-color);
}
.page-welcome {
    font-family: "SnesItalic";
    font-size: 70px;
    color: var(--main-component-color);
}
.large-text {
    font-size: 18px;
}
.small-text {
    font-size: 12px;
}
.modal {
    text-align: center;
}
.modal-body {
    background-color: #272727;
}
.image-modal {
    cursor: pointer;
}
.table-section {
    background-color: black;
}
.table-wrapper {
    max-height: 500px;
    overflow-y: auto;
    display: inline-block;
    height: 100%;
    width: 100%;
    padding-left: 3px;
    padding-right: 3px;
}
.right {
    text-align: right;
}
.left {
    text-align: left;
}
.game-filter {
    background-color: #3D3938;
    border-color: black !important;
    border-width: 2px !important;
}

/* custom scrollbar */

/* width */
::-webkit-scrollbar {
    width: 8px;
}  
/* Track */
::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey;  */
    border-radius: 10px;
    background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #272727; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--main-component-color);
}
/* Invert react-bootstrap carousel controls colour */
/* .carousel-control-next,
.carousel-control-prev, 
.carousel-indicators {
    filter: invert(100%);
}
.carousel-caption {
    color: #1e293a;
}
.carousel-caption {
    font-family: "SnesItalic";
    top: auto;
    bottom: 290px;
 } */
.carousel-item {
    text-align: center;
}
.table {
    border-collapse: separate;
    border-spacing:0 10px;
}
 /* table-stripped custom font size and colour */
.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(even)>td,
tr {
    font-size: 14px;
    color: #fff;
}
/* Set border-radius on the top-left and bottom-left of the first table data on the table row */
.table-striped>tbody>tr:nth-child(odd)>td:first-child,
.table-striped>tbody>tr:nth-child(odd)>th:first-child,
.table-striped>tbody>tr:nth-child(even)>td:first-child,
.table-striped>tbody>tr:nth-child(even)>th:first-child {
  border-radius: 10px 0 0 10px;
}
/* Set border-radius on the top-right and bottom-right of the last table data on the table row */
.table-striped>tbody>tr:nth-child(odd)>td:last-child,
.table-striped>tbody>tr:nth-child(odd)>th:last-child,
.table-striped>tbody>tr:nth-child(even)>td:last-child,
.table-striped>tbody>tr:nth-child(even)>th:last-child {
  border-radius: 0 10px 10px 0;
}
.link {
    text-decoration: none;
    color: #fff;
}
.link:hover {
    color: #FFB27A;
}
.link-button {
    text-decoration: none;
    width: "100%";
    display: flex;
    justify-content: center;
}
.image-gallery-row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
}
.image-gallery-column {
    flex: 50%;
    padding: 0 4px;
}
.image-gallery-column img {
    margin-bottom: 20px;
    vertical-align: middle;
    transition: transform .2s;
    width: 580px;
}
.image-gallery-column img:hover {
    transform: scale(1.2);
}
.game-stats-table {
    background-color: rgba(20, 20, 20, 0.6);
}
.platform-logo-button {
    filter: brightness(0.5) saturate(0%);
}
.input-group .btn-sm {
    background-color: var(--main-component-color);
}
.game-review-text {
    white-space: pre-wrap;
    color: #c4c4c4;
}
.review-card-text {
    color: #c4c4c4;
}
.game-review-rating {
   font-size: 20;
}